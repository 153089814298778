// comment to trigger build
/* eslint-disable camelcase */
import styled from '@emotion/styled'
import { Layout } from '@leshen/gatsby-theme-contentful'
import React from 'react'

import {
  Banner,
  Brandy,
  Image,
  LeshenPhoneCTA,
  List,
  ListItem,
  PackageCard,
  Price,
  SplitContent,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'

import { Columns, Dropdown, Typography } from '@leshen/ui'
import { Link as GatsbyLink, graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title, City } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            customLink={GatsbyLink}
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Get <br />
                  Frontier&reg; Fiber Internet
                </Typography>
                <Typography variant="h4">
                  in {City}, {State_Name}
                </Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Amazon eero Wi-Fi Pro 6 router included<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>$15/mo off YouTube TV for one year^</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Game with ultimate performance</Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  CALL
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Fiber Internet from Frontier: Reliable speeds for all your
                  needs
                </Typography>
                <Typography>
                  All Frontier Fiber Internet plans include a FREE premium Wi-Fi
                  router, NO data caps, and internet speeds that can power all
                  your devices.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package3}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />

              <PackageCard
                label={false}
                packageData={data.package4}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
              />
            </Columns>
          </VariableContent>

          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                  Check availability by city
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                  Find the best Internet deals in your area:
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.vrGuy.cloudinary[0].gatsbyImageData}
                alt={data.vrGuy.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.vrGuyMobile.cloudinary[0].gatsbyImageData}
                alt={data.vrGuyMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Fiber for serious internet users
                </Typography>
                <Typography variant="h4">
                  Own the internet like you never have with new top speeds as
                  fast as 5 Gig.
                </Typography>
                <Typography>
                  Stream 4K movies, get into VR gaming, and work from home on
                  dozens of devices at some of the fastest internet speeds on
                  the market.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>No data caps or overage charges</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Extreme bandwidth for smart homes with dozens of devices
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.momSonGaming.cloudinary[0].gatsbyImageData}
                alt={data.momSonGaming.cloudinary[0].context.custom.alt}
              />
            }
            mobileImage={
              <Image
                data={data.momSonGamingMobile.cloudinary[0].gatsbyImageData}
                alt={data.momSonGamingMobile.cloudinary[0].context.custom.alt}
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Choose Frontier Fiber Internet service in {City}
                </Typography>
                <Typography>
                  With Frontier Fiber Internet in {City}, your connection to
                  everything online is powered by a state-of-the-art fiber-optic
                  network to the home. Frontier offers free 24/7 technical
                  support, meaning you have the assistance you need when you
                  need it. Plus, when you choose Frontier Fiber Internet,
                  you&apos;ll enjoy both fast download and upload speeds to do
                  what you want online, whenever you want, with no limits.
                </Typography>
                <Typography>
                  You can stay connected when you bundle home phone with your
                  Frontier Fiber Internet today! Call to see what Frontier Fiber
                  Internet and home phone bundle deals are available to you.
                </Typography>
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.fatherDaughterMovie.cloudinary[0].gatsbyImageData}
                alt={data.fatherDaughterMovie.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={
                  data.fatherDaughterMovieMobile.cloudinary[0].gatsbyImageData
                }
                alt={
                  data.fatherDaughterMovieMobile.cloudinary[0].context.custom
                    .alt
                }
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Frontier Fiber Internet bundles in {City}
                </Typography>
                <Typography>
                  A Frontier bundle is the best way to have all your home
                  services needs in one place. Combine the power of Frontier
                  Fiber Internet with Frontier Home Phone and you&apos;ll save
                  money and streamline your bills every month. Plus, digital
                  VoIP technology offers advanced features to help you stay
                  better connected with the people that are important to you. It
                  doesn&apos;t get much better than that. Call today to find out
                  more about Frontier deals in {City}.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Perfect for families</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Consolidate your bills</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Enhanced communications</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Save money every month</Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.phone.cloudinary[0].gatsbyImageData}
                alt={data.phone.cloudinary[0].context.custom.alt}
              />
            }
            mobileImage={
              <Image
                data={data.phoneMobile.cloudinary[0].gatsbyImageData}
                alt={data.phoneMobile.cloudinary[0].context.custom.alt}
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Frontier Home Phone service in {City}
                </Typography>
                <Typography>
                  With Frontier Home Phone service, you can easily stay in touch
                  with friends and family across the nation or call into a work
                  meeting with crystal-clear call quality. Unlike cell phones,
                  which can drop your call, you&apos;ll be able to have great
                  conversations with friends and family when you get a home
                  phone plan from Frontier in {City}.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Unlimited Nationwide Calling</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Avoid using cell minutes at home</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Voicemail, Caller ID, & Call Waiting
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Enjoy high-quality, clear phone calls
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFour.symbol}
                  text={data.disclaimerFour.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFive.symbol}
                  text={data.disclaimerFive.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSix.symbol}
                  text={data.disclaimerSix.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "9f079251-dcfd-5307-993f-a204c2991dab" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "2f67302f-2fd4-55d3-8805-d6330dd58187" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    footerDisclaimers: allContentfulTemplate(
      filter: { name: { eq: "Default" } }
    ) {
      edges {
        node {
          footerDisclaimers {
            brandy {
              text
              symbol
            }
          }
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "52DWwjVrmw65yz5dKDbdjk" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuy: contentfulMedia(contentful_id: { eq: "74Sk7cjUnv5TzoGM9A9QL4" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuyMobile: contentfulMedia(
      contentful_id: { eq: "7uf13p4haNbePfmzS0lPOL" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    momSonGaming: contentfulMedia(
      contentful_id: { eq: "65eG6aWZdSiSx9D5LUxXRi" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    momSonGamingMobile: contentfulMedia(
      contentful_id: { eq: "2dA919Updx8j4uYHkRqihL" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughterMovie: contentfulMedia(
      contentful_id: { eq: "6vCS6oUMgarjrgVoxF5ZRy" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughterMovieMobile: contentfulMedia(
      contentful_id: { eq: "4oFq6ps42T9d21SF3y0Gws" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phone: contentfulMedia(contentful_id: { eq: "264aNICaC50Jw6uh87xOkJ" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phoneMobile: contentfulMedia(
      contentful_id: { eq: "3EWyu3ec3lOo5QMom3D1eq" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-index-hero" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-5gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-2gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-1gig" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package4: brandyPackage(brandy_id: { eq: "ftr-sigil-500mbp" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-rewardcard-fiber" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-eeroo" }) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-archer-router" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-fibermptp" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-youtubetv" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(brandy_id: { eq: "disclaimer-ftr-fiber" }) {
      id
      text
      symbol
    }
  }
`
